body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100vh;
  min-width: 1024px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

th[class*='TableHead__HeadCell'] {
  vertical-align: middle;
}

tr > td:nth-child(1) {
  padding: 0;
}